<template>
  <!-- 投诉建议及问题 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div
      class="btnList1 wtl_btnList"
      ref="btnList"
    >
      <div class="btnList1 wtl_btnList1">
        <el-button
          v-if="hasPermiss('ycgl_tuoSuJianYi:add')"
          class="itemBtn btnColor"
          @click="addQuestion(0)"
        >新建问题</el-button>
        <!-- <el-button class="itemBtn btnColor" @click="delQuestion"
              >删除问题</el-button
            > -->
        <el-button
          v-if="hasPermiss('ycgl_tuoSuJianYi:update')"
          class="itemBtn btnColor"
          @click="addQuestion(1)"
        >修改</el-button>
        <el-button
          v-if="hasPermiss('ycgl_tuoSuJianYi:handel')"
          class="itemBtn btnColor"
          @click="dealQuestion"
        >问题处理</el-button>
        <el-button
          class="itemBtn btnColor"
          @click="myModel"
        >表格设置</el-button>
        <el-dropdown @command="handleCommand_dc">
          <el-button class="dropdownBtn">导出<i class="el-icon-arrow-down el-icon--right"></i></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dc">导出</el-dropdown-item>
            <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
            <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        class="singleinpbtn wtl_queryBoxNew"
        v-if="hasPermiss('ycgl_tuoSuJianYi:query')"
      >
        <el-button
          class="seniorbut"
          @click="queryBtn_ok"
          icon="el-icon-search"
        >查询</el-button>
        <el-tooltip
          content="查询项恢复初始状态"
          placement="top"
        >
          <div
            class="condition"
            @click="queryBtn_cz"
          >清除</div>
        </el-tooltip>
        <el-tooltip
          content="查询更多条件"
          placement="top"
        >
          <div
            class="condition"
            @click="more"
          >
            {{ MoreConditions }}
          </div>
        </el-tooltip>
      </div>
    </div>
    <!-- 查询条件 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">问题编号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="number1"
                placeholder="请输入内容"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.memberId"
                placeholder="请输入"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员名称:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.memberName"
                placeholder="请输入"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">快递公司:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.expressName"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in expressArr"
                  :key="item.expressId"
                  :label="item.expressName"
                  :value="item.expressId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">快递单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.courierNumber"
                placeholder="请输入内容"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">仓库:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.storageId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in storageArr"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">问题类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.questionTypeTitle"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in questionTypeArr"
                  :key="item.questionTypeId"
                  :label="item.title"
                  :value="item.title"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">处理状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.handleStatus"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in wentiList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">处理类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.handleTypeName"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dealType"
                  :key="item.questionHandleTypeId"
                  :label="item.handleTypeName"
                  :value="item.questionHandleTypeId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- <div class="item_left" v-if="hasPermiss('ycgl_tuoSuJianYi:query')">
          <div class="singleinpbtn" v-if="hasPermiss('ycgl_tuoSuJianYi:query')">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div> -->
      </div>
    </el-form>

    <!-- 表格 -->
    <el-table
      :height="tableHeigth"
      ref="mytable"
      @row-click="rowclick"
      :row-class-name="tableRowClassName"
      highlight-current-row
      :header-cell-style="{ background: '#f0f0f0' }"
      :data="tableData"
      size="small"
      border
      show-summary
      :summary-method="getSummaries"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        fixed="left"
        label="序号"
        align="center"
        type="index"
        width="55"
      >
      </el-table-column>
      <el-table-column
        fixed="left"
        type="selection"
        align="center"
        width="55"
      >
      </el-table-column>
      <template v-for="(item, index) in myTableHeard">
        <el-table-column
          :key="index"
          sortable
          :label="item.name"
          :prop="item.field"
          :show-overflow-tooltip="true"
          align="center"
          :min-width="item.width"
          :data-isTotal="item.isTotal"
          v-if="item && item.isShow"
        >
        </el-table-column>
      </template>
    </el-table>
    <!-- 分页组件 -->
    <paging
      :pageNum="pageStart"
      :total="pageCount"
      :sizeList="sizeList"
      :size="pageTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
    </paging>
    <!-- //排序 -->
    <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      @closeDias="closeDias"
    ></sorttable>
    <!-- 自定义排序表头 -->
    <el-dialog
      title="上传编辑好的导出模板"
      :visible.sync="show_upload"
      append-to-body
      width="40%"
      center
    >
      <el-upload
        :action="uploadUrl"
        :show-file-list="true"
        :file-list="fileList"
        :limit="1"
        :http-request="uploadSectionFile"
      >
        <el-button
          size="small"
          type="primary"
        >点击上传</el-button>
        <div
          slot="tip"
          class="el-upload__tip"
        >上传模板</div>
      </el-upload>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="show_upload = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'
import draggable from 'vuedraggable' //拖拽组件
import sorttable from '@/components/sortTemplate.vue'
import paging from '@/components/pagings.vue'

export default {
  inject: ['reload'],
  components: { draggable, paging, sorttable },
  data() {
    return {
      isActive: true, //  输入框绑定动态class
      judge: false, //  输入框绑定动态class
      MoreConditions: '更多条件', //  输入框绑定动态class
      queryData: {},
      expressArr: [], //  快递公司下拉
      storageArr: [], //  仓库下拉
      questionTypeArr: [], //  问题类型下拉
      dealType: [], //  处理类型下拉
      tableHeigth: '',
      tableData: [],
      number1: '',
      tabelHeadeTitle: [
        {
          name: '问题编号',
          field: 'questionNumber',
          width: '180',
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '会员号',
          field: 'memberId',
          width: '80',
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '仓库',
          field: 'storageName',
          width: '80',
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '快递公司',
          field: 'expressName',
          width: '120',
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '快递单号',
          field: 'courierNumber',
          width: '80',
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '包裹状态',
          field: 'stockStatusShow',
          width: '80',
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '异常类型',
          field: 'questionTypeTitle',
          width: '80',
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '异常状态',
          field: 'handleStatusShow',
          width: '80',
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '包裹件数',
          field: 'count',
          width: '80',
          sort: 12,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: '新建时间',
          field: 'questionCreateTime',
          width: '120',
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '操作人',
          field: '',
          width: '120',
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '处理时间',
          field: '',
          width: '120',
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '处理人',
          field: '',
          width: '120',
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      myTableHeard: [], //  我的表头
      totalArr: [], //  需要合计的字段
      sizeList: [10, 20, 50, 100, 200],
      pageStart: 1, //  页码
      pageCount: 0,
      pageTotal: 50, //  一页几条
      singleData: [],
      // ================
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      className: '', //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: '', //上传路径
      originalTabelHeadeTitle: [],
      sortName: '模板名', //排序名
      wentiList: [
        { name: '未处理', value: 'wei:handle_status' },
        { name: '已处理', value: 'yi:handle_status' },
      ],
      pageGroupName: 'complaint', //页面标识
    }
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1
      }
      this.tabelHeadeTitle = arr
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      )
    }
    this.getGroupFindFieldSort() //  获取用户自定义表头
    // this.getAbnormalList()             //  处理状态
    this.getDealQuestionType() //  处理类型
    this.getQuestionTypeList() //  问题类型
    this.getCangkuList() //  仓库下拉
    this.getExpressList() //  快递公司下拉
  },
  mounted() {
    this.getData()
    // window.onresize = () => {
    //   return (() => {
    //     this.fetTableHeight();
    //   })();
    // };
  },
  watch: {},
  methods: {
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row)
    },
    tableRowClassName({ row, rowIndex }) {
      //  表格样式i
      if (rowIndex % 2 === 0) {
        return 'warning-row'
      }
    },
    getData() {
      //  获取数据
      this.queryData.pageStart = this.pageStart
      this.queryData.pageTotal = this.pageTotal
      Api.questionFeedbacks(this.queryData).then((res) => {
        if (res.data.status === 'success') {
          this.className = res.data.result.className || ''
          this.tableData = res.data.result.data || []
          this.pageCount = res.data.result.pageCount || 0
        }
      })
    },
    more() {
      //  输入框绑定动态class
      this.isActive = !this.isActive
      this.judge = !this.judge
      this.fetTableHeight()
      if (this.judge) {
        this.MoreConditions = '收起条件'
      } else {
        this.MoreConditions = '更多条件'
      }
    },

    queryBtn_ok() {
      // 查询按钮
      let nidlist = new Array()
      nidlist = tools.getOrderNum(this.number1)
      this.queryData.questionNumber = nidlist
      this.pageStart = 1
      this.getData()
    },

    queryBtn_cz() {
      // 重置按钮
      this.number1 = ''
      this.queryData = {}
    },

    getCangkuList() {
      //  仓库下拉
      Api.getStorageList().then((res) => {
        if (res.data.status === 'success') {
          this.storageArr = res.data.result
        } else {
          console.log(`${res.data.message}--仓库下拉`)
        }
      })
    },
    getExpressList() {
      //  快递公司
      Api.expressChooseList().then((res) => {
        if (res.data.status === 'success') {
          this.expressArr = res.data.result
        } else {
          console.log(`${res.data.message}--快递公司`)
        }
      })
    },
    getDealQuestionType() {
      //  处理类型
      Api.dealQuestionType().then((res) => {
        if (res.data.status === 'success') {
          this.dealType = res.data.result
        } else {
          console.log(`${res.data.message}--处理类型`)
        }
      })
    },
    getQuestionTypeList() {
      //  问题类型
      Api.questionTypeList().then((res) => {
        if (res.data.status === 'success') {
          this.questionTypeArr = res.data.result
        } else {
          console.log(`${res.data.message}--问题类型`)
        }
      })
    },

    addQuestion(num) {
      //  新建问题
      let query = {}
      if (num === 1) {
        if (this.singleData.length !== 1) {
          this.$message.warning('请选择一条数据')
          return
        }
        query = {
          id: this.singleData[0].questionId,
        }
      }
      this.$router.push({
        path: '/exceptionhandling/NewQuestion',
        query: query,
      })
    },
    dealQuestion() {
      //  问题处理
      if (this.singleData.length === 1) {
        this.$router.push({
          path: '/exceptionhandling/NewQuestion',
          query: {
            dealId: this.singleData[0].questionId,
            id: this.singleData[0].questionId,
          },
        })
      } else {
        this.$message.warning('请选择一条数据')
      }
    },
    //导出操作
    handleCommand_dc(command) {
      if (command == 'dc') {
        // 导出
        this.exportBtn()
      } else if (command == 'dcmb') {
        // 下载模板
        this.exportTemplateDownload()
      } else if (command == 'scmb') {
        // 上传模板
        this.upLoadBtn()
      }
    },

    //删除问题
    DeleteQuestion() {
      this.$confirm('是否删除未处理的问题?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).then(() => {
        this.$message.success('删除成功')
      })
    },
    //修改
    modifybut() {
      this.$router.push('/exceptionhandling/NewQuestion?pageType=modify')
    },
    //问题类型
    ProblemHandling() {
      this.$router.push('/exceptionhandling/NewQuestion?pageType=see')
    },
    // 上传导出模板
    uploadSectionFile(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name

      // 根据后台需求数据格式
      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append('fileName', fileName)
      let signArr = [
        {
          key: 'fileName',
          val: fileName,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      form.append('sign', sign)
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == 'success') {
            let fileName = res.data.result.fileName

            let signArr = [
              {
                key: 'groupName',
                val: this.pageGroupName,
              },
              {
                key: 'templateName',
                val: this.sortName,
              },
              {
                key: 'fileName',
                val: fileName,
              },
            ]
            let sign_ = tools.getMD5Sign(signArr)
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || '更新成功')
              this.show_upload = false
            })
          }
        })
        .catch((err) => {})
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName
      var head = this.head
      if (head.length == 0) {
        let fieldNames = []
        let heads = []
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field)
            heads.push(this.myTableHeard[i].name)
          }
        }
        fieldName = fieldNames
        head = heads
      }
      //加密数组格式
      let signArr = [
        {
          key: 'fieldName',
          val: fieldName,
        },
        {
          key: 'head',
          val: head,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      }
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || ''
        //下载(这里其实是直接访问)
        var a = document.createElement('a')
        a.setAttribute('href', downloadurl)
        a.setAttribute('target', '_blank')
        a.click()
      })
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this
      let datas = that.tableData
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      }
      let signarr = tools.getSignArr(parm)
      let signs_e = tools.getMD5Sign(signarr)
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl
        var a = document.createElement('a')
        a.setAttribute('href', downloadurl)
        a.setAttribute('target', '_blank')
        a.click()
      })
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true
    },
    // 点击导出按钮
    exportBtn() {
      let that = this
      let fieldName = []
      let head = []
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field)
          head.push(this.myTableHeard[i].name)
        }
      }
      this.fieldName = fieldName
      this.head = head

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId
          that.templateDataExport(customizedExportId)
        } else {
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            '投诉建议问题列表'
          )
        }
      })
    },
    // ===============================================导出部分=结束======

    // 提取快递单输入框的内容(无规则输入,定义了 换行,',' ,'空格三种')
    getStr(orderNos) {
      let arr = []
      let arr_1 = []
      if (orderNos.indexOf('\n') !== -1) {
        arr = orderNos.split('\n')
        //  ['123 344,dsg ewe','234 233','32,234']
        for (var i = 0; i < arr.length; i++) {
          let arr_a = []
          if (arr[i].indexOf(',') !== -1) {
            arr_a = arr[i].split(',')
            arr_a.map((item) => {
              arr_1.push(item)
            })
          } else {
            if (arr[i]) {
              arr_1.push(arr[i])
            }
          }
        }
        setTimeout(() => {
          for (var j = 0; j < arr_1.length; j++) {
            let arr_b = []
            if (arr_1[j].indexOf(' ') !== -1) {
              arr_b = arr_1[j].split(' ')
              arr_b.map((item) => {
                arr_b.push(item)
              })
            } else {
              if (arr_1[j]) {
                arr_b.push(arr_1[j])
              }
            }
          }
        }, 20)
      } else if (orderNos.indexOf(',') !== -1) {
        arr = orderNos.split(',')
        //  ['123 344','234','32 234']
        for (var b = 0; b < arr.length; b++) {
          let arr_a = []
          if (arr[b].indexOf(' ') !== -1) {
            arr_a = arr[b].split(' ')
            arr_a.map((item) => {
              arr_1.push(item)
            })
          } else {
            if (arr[b]) {
              arr_1.push(arr[b])
            }
          }
        }
      } else if (orderNos.indexOf(' ') !== -1) {
        arr = orderNos.split(' ')
        arr.map((item) => {
          if (item) {
            arr_1.push(item)
          }
        })
      } else {
        arr_1 = [orderNos]
      }
      let endArr = []
      for (var q = 0; q < arr_1.length; q++) {
        if (arr_1[q].indexOf(' ') != -1) {
          let arrs = arr_1[q].split(' ')
          for (var w = 0; w < arrs.length; w++) {
            if (arrs[w]) {
              endArr.push(arrs[w])
            }
          }
        } else {
          if (arr_1[q]) {
            endArr.push(arr_1[q])
          }
        }
      }
      return endArr
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || []
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject)
            this.sortName = arr[0].sortName
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle
        }
        let totalArr = []
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field)
          }
        }
        that.totalArr = totalArr
      })

      that.$nextTick(() => {
        that.$refs.mytable.doLayout()
      })
    },
    // 设置模板按钮
    myModel() {
      let that = this
      that.show_sortTableHeard = true
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard))
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true
      } else {
        that.allCheck = false
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.singleData = e
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e
      this.myTableHeard = this.tabelHeadeTitle
      let sortObject = this.myTableHeard
      let signs = [
        {
          key: 'sortName',
          val: this.sortName, //模板名称
        },
        {
          key: 'groupName',
          val: this.pageGroupName, //页面标识
        },
        {
          key: 'sortObject',
          val: JSON.stringify(sortObject),
        },
      ]

      let sign = tools.getMD5Sign(signs)

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      }
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || '操作成功')
        this.getGroupFindFieldSort()
      })
      this.show_sortTableHeard = false //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
      this.show_sortTableHeard = false
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this
      that.tabelHeadeTitle[idx].isShow = e
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true
      } else {
        that.allCheck = false
      }
    },
    //全选
    checkAll(e) {
      let that = this
      this.allCheck = e
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e
      }
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e
    },
    // 合计
    getSummaries(param) {
      let that = this
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2)
                } else {
                  return prev
                }
              }, 0)
              sums[index] += ''
            } else {
              sums[index] = '---'
            }
          }
        }
      })
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
      return sums
    },

    //分页
    handleSizeChange(val) {
      this.size = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    // 上一页
    handlePrevPage() {
      this.pageNum = this.pageNum - 1 || 1
      this.getData()
    },
    // 下一页
    handleNextPage() {
      this.pageNum = this.pageNum + 1 || 1
      this.getData()
    },

    //    ---------  设置高度  ----------------

    resetHeight() {
      // 重置table高度
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0
        resolve()
      })
    },

    fetTableHeight() {
      // 设置table高度
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight()
        this.$nextTick(() => {
          this.$refs.mytable.doLayout()
        })
      })
    },

    getHeight() {
      // 获取表格高度
      let windonHeight = document.body.clientHeight
      let queryHeigth
      let btnListHeight = this.$refs.btnList.offsetHeight + 16 // 按钮列表  16==上下的margin
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight //查询框
      } else {
        queryHeigth = 50
      }
      let pagsHeigth = 40 //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10 //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || '50vh' //
    },
  },
}
</script>

<style scoped lang="scss">
</style>
